import { version } from '../../../package.json';
import { PLUGIN_NAME } from './constants';
import pageBuilderComponents from './page-builder-components';
import variants from './page-builder-variants';

async function load(app) {
  app.events.on('load:custom:page-builder:component', addComponentType => {
    pageBuilderComponents.forEach(component => {
      addComponentType({ type: component.type, component });
    });
  });

  app.events.on('load:custom:page-builder:variant', addVariant => {
    variants.forEach(variant => {
      addVariant(variant.key, variant);
    });
  });

  return {
    name: PLUGIN_NAME,
    version
  };
}

load.pluginName = PLUGIN_NAME;

export default load;
