export const ecommerceProductView = {
  type: 'ecommerce-product-view',
  name: 'Ecommerce: Product view',
  icon: 'fas fa-shopping-cart',
  settings: {},
  formSchema: {
    properties: {
      name: {
        type: 'string',
        showInForm: true,
        formFieldType: 'text'
      }
    },
    required: []
  },
  color: 'deep-purple',
  group: 'data'
};
