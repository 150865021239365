import { commonProperties } from '../constants';

export const charterRateSummary = {
  type: 'charter-rate-summary',
  name: 'Charter rate summary',
  icon: 'fas fa-bars',
  settings: {},
  formSchema: {
    properties: {
      ...commonProperties
    }
  },
  allowChildren: false,
  color: 'purple',
  group: 'data'
};
