export const newsletterSignup = {
  type: 'newsletter-signup',
  name: 'Newsletter Signup',
  icon: 'fas fa-envelope',
  settings: {},
  formSchema: {
    properties: {
      name: {
        type: 'string',
        showInForm: true,
        formFieldType: 'text'
      },
      embed: {
        label: 'Mode',
        type: 'string',
        showInForm: true,
        formFieldType: 'select',
        options: [
          ['false', 'Open in a popup'],
          ['true', 'Embed in body'],
          ['full-page', 'Full Page'],
          ['repeat', 'Embed in text']
        ]
      },
      buttonType: {
        label: 'Button type (works only if "Mode" = "Open in a popup")',
        type: 'string',
        showInForm: true,
        formFieldType: 'select',
        options: [
          ['regular', 'Regular'],
          ['mobile-sticky', 'Sticky for mobile'],
          ['desktop-body-modal', 'Desktop (body modal)'],
          ['desktop-sidebar', 'Desktop (sidebar)']
        ]
      },
      productBriefing: {
        label: 'Checkbox for "BOAT Briefing"',
        type: 'boolean',
        showInForm: true,
        formFieldType: 'checkbox'
      },
      productBusinessNews: {
        label: 'Checkbox for "BOAT Business"',
        type: 'boolean',
        showInForm: true,
        formFieldType: 'checkbox'
      },
      productDeepDive: {
        label: 'Checkbox for "Deep Dive"',
        type: 'boolean',
        showInForm: true,
        formFieldType: 'checkbox'
      },
      productEvents: {
        label: 'Checkbox for "Event Updates and Invitations"',
        type: 'boolean',
        showInForm: true,
        formFieldType: 'checkbox'
      },
      productMarketing: {
        label: 'Checkbox for "BOAT Marketing Messages"',
        type: 'boolean',
        showInForm: true,
        formFieldType: 'checkbox'
      },
      productPodcast: {
        label: 'Checkbox for "Podcasts"',
        type: 'boolean',
        showInForm: true,
        formFieldType: 'checkbox'
      },
      buttonTitle: {
        label: 'Button title (if mode = popup) / Subtitle (otherwise)',
        type: 'string',
        showInForm: true,
        formFieldType: 'text'
      },
      title: {
        label: 'Title',
        type: 'string',
        showInForm: true,
        formFieldType: 'text'
      },
      insertionType: {
        label: 'Insertion type',
        type: 'string',
        showInForm: true,
        formFieldType: 'select',
        options: [['element', 'Element'], ['textblock', 'Text block']],
        selected: 'element'
      },
      startingPoint: {
        type: 'number',
        showInForm: true,
        formFieldType: 'number',
        label: 'Starting point'
      },
      repeatEvery: {
        type: 'number',
        showInForm: true,
        formFieldType: 'number',
        label: 'Repeat every'
      },
      maxRepetitions: {
        type: 'number',
        showInForm: true,
        formFieldType: 'number',
        label: 'Max repetitions',
        default: false
      }
    },
    required: ['name', 'buttonTitle', 'buttonType', 'title']
  },
  allowChildren: true,
  color: 'green',
  group: 'ui'
};
