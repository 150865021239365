import { commonProperties } from '../constants';

export const header = {
  type: 'header',
  name: 'Header',
  icon: 'fas fa-bars',
  settings: {},
  formSchema: {
    properties: {
      ...commonProperties,
      bannerHideTime: {
        label: 'Top sticky banner animation time',
        type: ['integer', 'null'],
        showInForm: true,
        formFieldType: 'number'
      },
      onlyStickBannerRowOnce: {
        label: 'Only stick banner row once',
        type: 'boolean',
        formFieldType: 'switches',
        showInForm: true,
        default: false
      }
    },
    required: []
  },
  allowChildren: false,
  color: 'blue',
  group: 'ui'
};
