import React from 'react';
import PropTypes from 'prop-types';
import { EditorViewWrapper } from '@blaze-cms/plugin-page-builder-ui';
import { gql, useQuery } from '@apollo/client';
import './styles.scss';

const getQueryName = entity => {
  if (entity === 'article') return 'getPublishedArticle';
  if (entity === 'broker') return 'getPublishedBroker';
  if (entity === 'exhibitor') return 'getPublishedExhibitor';
  if (entity === 'page') return 'getPublishedPage';
  if (entity === 'podcast') return 'getPublishedPodcast';
  if (entity === 'profile') return 'getPublishedProfile';
  if (entity === 'yacht_for_charter') return 'getPublishedYachtForCharter';
  if (entity === 'yacht_for_sale') return 'getPublishedYachtForSale';
  if (entity === 'yacht_page') return 'getPublishedYachtPage';

  return 'getPublishedArticle';
};

const getEntityId = (
  entity,
  {
    articleId,
    brokerId,
    exhibitorId,
    pageId,
    podcastId,
    profileId,
    yachtPageId,
    yachtForCharterId,
    yachtForSaleId
  }
) => {
  if (entity === 'article') return articleId;
  if (entity === 'broker') return brokerId;
  if (entity === 'exhibitor') return exhibitorId;
  if (entity === 'page') return pageId;
  if (entity === 'podcast') return podcastId;
  if (entity === 'profile') return profileId;
  if (entity === 'yacht_for_charter') return yachtPageId;
  if (entity === 'yacht_for_sale') return yachtForCharterId;
  if (entity === 'yacht_page') return yachtForSaleId;

  return null;
};

const OnwardJourney = ({ entity, prefix, title, entityId }) => {
  const { data, error, loading } = useQuery(
    gql`
    query getEntityData($id: String!, $entity: String!){
      entityData: ${getQueryName(entity)}(id: $id){
        name,
        id,
        __typename
      }
      url: getEntityUrl(fullUrl: true, itemId: $id, itemEntity: $entity)
    }
  `,
    {
      variables: { id: entityId, entity }
    }
  );

  if (loading) return 'loading...';
  if (error) return error.message;
  if (!data || !data.entityData) return null;

  return (
    <a href={data.url} target="_blank" rel="noopener noreferrer">
      <span className="onward-journey__prefix">{prefix || 'Read More'}</span>

      <span className="onward-journey__separator">/</span>

      <span className="onward-journey__title">{title || data.entityData.name}</span>
    </a>
  );
};

OnwardJourney.propTypes = {
  entity: PropTypes.string.isRequired,
  prefix: PropTypes.string,
  title: PropTypes.string,
  entityId: PropTypes.string.isRequired
};

OnwardJourney.defaultProps = {
  prefix: null,
  title: null
};

const OnwardJourneyEditorView = props => {
  const {
    component: { settings }
  } = props;
  const entityId = getEntityId(settings.entity, settings);
  if (!entityId || !settings.entity) return 'Link target not set';

  return <OnwardJourney {...settings} entityId={entityId} />;
};
OnwardJourneyEditorView.propTypes = {
  component: PropTypes.object.isRequired
};

const EditorViewComponent = props => (
  <EditorViewWrapper {...props} EditorViewRender={OnwardJourneyEditorView} />
);

export default EditorViewComponent;
