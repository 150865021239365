import React from 'react';
import { TbSailboat } from 'react-icons/tb';

const YachtIcon = () => (
  <span>
    <TbSailboat style={{ color: '#63779C', width: '24px', height: '24px' }} />
  </span>
);

export default YachtIcon;
