const commonProperties = {
  name: {
    type: 'string',
    showInForm: true,
    formFieldType: 'text'
  },
  modifier: {
    label: 'Modifier',
    type: 'string',
    showInForm: true,
    formFieldType: 'text'
  }
};

const newsletterIdString = {
  type: 'string',
  showInForm: true,
  formFieldType: 'text',
  label: 'Abacus newsletter ids to add user to (e.g. 1,5,3)'
};

const editorViewProperties = {
  canShowInEditorView: {
    type: 'boolean',
    showInForm: false,
    default: true
  },
  showInEditorView: {
    type: 'boolean',
    showInForm: false,
    default: true
  }
};

const LOGIN = 'login';
const MENU = 'menu';
const MENU_ITEM = 'menuitem';
const SUB_MENU = 'submenu';
const SEARCH_FILTER = 'searchfilter';
const SEARCH_FILTER_SORT = 'searchfiltersort';
const MODAL = 'modal';
const REGISTRATION_FORM = 'registration-form';
const RESTRICTED_USER_UPDATE_FORM = 'restricted-user-update-form';

const colorOptions = [['', 'Default'], ['blue', 'Blue'], ['orange', 'Orange']];

const promotionTypeOptions = [
  ['standard', 'Standard'],
  ['featured', 'Featured'],
  ['sponsored', 'Sponsored'],
  ['inherit', 'Inherit']
];

export {
  editorViewProperties,
  commonProperties,
  colorOptions,
  promotionTypeOptions,
  LOGIN,
  MENU,
  MENU_ITEM,
  SUB_MENU,
  SEARCH_FILTER,
  SEARCH_FILTER_SORT,
  MODAL,
  REGISTRATION_FORM,
  RESTRICTED_USER_UPDATE_FORM,
  newsletterIdString
};
