import { commonProperties, editorViewProperties } from '../constants';
import SettingsComponent from './Settings';
import EditorViewComponent from './EditoriViewComponent';

export const onwardJourney = {
  type: 'onward-journey',
  name: 'Onward Journey',
  icon: 'fas fa-forward',
  toolbarOrder: 8,
  settings: {
    canShowInEditorView: true,
    showInEditorView: true
  },
  EditorViewComponent,
  formSchema: {
    properties: {
      ...editorViewProperties,
      ...commonProperties,
      entity: {
        label: 'Entity',
        type: 'string',
        showInForm: true,
        formFieldType: 'select',
        options: [
          ['article', 'Article'],
          ['broker', 'Broker'],
          ['exhibitor', 'Exhibitor'],
          ['page', 'Page'],
          ['podcast', 'Podcast'],
          ['yacht_for_charter', 'Yacht for Charter'],
          ['yacht_for_sale', 'Yacht for Sale'],
          ['yacht_page', 'SYD Yacht'],
          ['profile', 'Yacht Professional']
        ]
      },
      prefix: {
        label: 'Prefix (by default "Read more")',
        type: 'string',
        showInForm: true,
        formFieldType: 'text'
      },
      title: {
        label: "Title (by default the entity's name)",
        type: 'string',
        showInForm: true,
        formFieldType: 'text'
      },
      articleId: {
        type: 'string',
        showInForm: true,
        isRequired: false,
        label: 'Article',
        formFieldType: 'relation',
        relation: {
          relationType: 'hasOne',
          entityIdentifier: 'article',
          foreignKey: 'articleId',
          description: 'Article',
          label: 'Article',
          formFieldType: 'relationStandard'
        }
      },
      brokerId: {
        type: 'string',
        showInForm: true,
        isRequired: false,
        label: 'Broker',
        formFieldType: 'relation',
        relation: {
          relationType: 'hasOne',
          entityIdentifier: 'broker',
          foreignKey: 'brokerId',
          description: 'Broker',
          label: 'Broker',
          formFieldType: 'relationStandard'
        }
      },
      exhibitorId: {
        type: 'string',
        showInForm: true,
        isRequired: false,
        label: 'Exhibitor',
        formFieldType: 'relation',
        relation: {
          relationType: 'hasOne',
          entityIdentifier: 'exhibitor',
          foreignKey: 'exhibitorId',
          description: 'Exhibitor',
          label: 'Exhibitor',
          formFieldType: 'relationStandard'
        }
      },
      pageId: {
        type: 'string',
        showInForm: true,
        isRequired: false,
        label: 'Page',
        formFieldType: 'relation',
        relation: {
          relationType: 'hasOne',
          entityIdentifier: 'page',
          foreignKey: 'pageId',
          description: 'Page',
          label: 'Page',
          formFieldType: 'relationStandard'
        }
      },
      podcastId: {
        type: 'string',
        showInForm: true,
        isRequired: false,
        label: 'Podcast',
        formFieldType: 'relation',
        relation: {
          relationType: 'hasOne',
          entityIdentifier: 'podcast',
          foreignKey: 'podcastId',
          description: 'Podcast',
          label: 'Podcast',
          formFieldType: 'relationStandard'
        }
      },
      yachtPageId: {
        type: 'string',
        showInForm: true,
        isRequired: false,
        label: 'SYD Yacht',
        formFieldType: 'relation',
        relation: {
          relationType: 'hasOne',
          entityIdentifier: 'yacht_page',
          foreignKey: 'yachtPageId',
          description: 'SYD Yacht',
          label: 'SYD Yacht',
          formFieldType: 'relationStandard'
        }
      },
      yachtForCharterId: {
        type: 'string',
        showInForm: true,
        isRequired: false,
        label: 'Yacht for Charter',
        formFieldType: 'relation',
        relation: {
          relationType: 'hasOne',
          entityIdentifier: 'yacht_for_charter',
          foreignKey: 'yachtForCharterId',
          description: 'Yacht for Charter',
          label: 'Yacht for Charter',
          formFieldType: 'relationStandard'
        }
      },
      yachtForSaleId: {
        type: 'string',
        showInForm: true,
        isRequired: false,
        label: 'Yacht for Sale',
        formFieldType: 'relation',
        relation: {
          relationType: 'hasOne',
          entityIdentifier: 'yacht_for_sale',
          foreignKey: 'yachtForSaleId',
          description: 'Yacht for Sale',
          label: 'Yacht for Sale',
          formFieldType: 'relationStandard'
        }
      },
      profileId: {
        type: 'string',
        showInForm: true,
        isRequired: false,
        label: 'Yacht Professional',
        formFieldType: 'relation',
        relation: {
          relationType: 'hasOne',
          entityIdentifier: 'profile',
          foreignKey: 'profileId',
          description: 'Yacht Professional',
          label: 'Yacht Professional',
          formFieldType: 'relationStandard'
        }
      }
    },
    required: ['entity']
  },
  allowChildren: false,
  color: 'blue',
  SettingsComponent,
  group: 'ui'
};
