import { commonProperties } from '../constants';

export const userAccessRefresh = {
  type: 'user-access-refresh',
  name: 'User access refresh',
  icon: 'fa-circle',
  settings: {},
  formSchema: {
    properties: {
      ...commonProperties,
      resetQueryString: {
        type: 'string',
        showInForm: true,
        formFieldType: 'text',
        label: 'Url query string to indicate reset should happen (e.g. reset=true)'
      }
    }
  },
  allowChildren: false,
  color: 'blue',
  group: 'ui'
};
