import { commonProperties } from '../constants';

export const yachtDescription = {
  type: 'yacht-description',
  name: 'Yacht description',
  icon: 'fas fa-ship',
  settings: {},
  formSchema: {
    properties: {
      ...commonProperties,
      useCustomDescription: {
        type: 'boolean',
        showInForm: true,
        formFieldType: 'switches',
        label: 'Add custom description',
        default: false
      },
      customDescription: {
        type: 'string',
        showInForm: true,
        label: 'Custom description',
        formFieldType: 'editor'
      }
    },
    required: []
  },
  allowChildren: false,
  color: 'media-color',
  group: 'media'
};
