import React, { useEffect, useState } from 'react';
import { FormBuilder } from '@blaze-cms/react-form-builder';
import cloneDeep from 'lodash.clonedeep';
import PropTypes from 'prop-types';

const Settings = ({ schema, data, onChange }) => {
  const [schemaWithData, setSchemaWithData] = useState(schema);
  const [currentData, setCurrentData] = useState({ ...data.values });

  const handleEntity = entity => {
    const clonedSchema = cloneDeep(schemaWithData);

    clonedSchema.properties.articleId.showInForm = entity === 'article';
    clonedSchema.properties.brokerId.showInForm = entity === 'broker';
    clonedSchema.properties.exhibitorId.showInForm = entity === 'exhibitor';
    clonedSchema.properties.pageId.showInForm = entity === 'page';
    clonedSchema.properties.podcastId.showInForm = entity === 'podcast';
    clonedSchema.properties.profileId.showInForm = entity === 'profile';
    clonedSchema.properties.yachtForCharterId.showInForm = entity === 'yacht_for_charter';
    clonedSchema.properties.yachtForSaleId.showInForm = entity === 'yacht_for_sale';
    clonedSchema.properties.yachtPageId.showInForm = entity === 'yacht_page';

    setSchemaWithData(clonedSchema);
  };

  useEffect(
    () => {
      handleEntity(currentData.entity || 'article');
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const interceptOnChange = ({ fields, isNewValueSet, isValid, valuesChecked }) => {
    const values = valuesChecked.reduce((acc, { id, value }) => {
      acc[id] = value;
      return acc;
    }, {});

    const newCurrentData = { ...currentData, ...values };

    setCurrentData(newCurrentData);
    handleEntity(newCurrentData.entity || 'article');

    onChange({
      fields,
      isNewValueSet,
      isValid,
      valuesChecked
    });
  };

  const parsedData = { values: { ...currentData } };

  return <FormBuilder schema={schemaWithData} data={parsedData} onChange={interceptOnChange} />;
};

Settings.propTypes = {
  schema: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func
};

Settings.defaultProps = {
  onChange: () => {}
};

export default Settings;
