import {
  commonProperties,
  newsletterIdString,
  MENU,
  SUB_MENU,
  MENU_ITEM,
  SEARCH_FILTER,
  SEARCH_FILTER_SORT,
  REGISTRATION_FORM,
  MODAL
} from '../constants';

export const registrationForm = {
  type: REGISTRATION_FORM,
  name: 'Registration form',
  icon: 'fa-circle',
  settings: {},
  formSchema: {
    properties: {
      ...commonProperties,
      newsletterIdString
    }
  },
  allowChildren: true,
  notAllowedChildrenTypes: [
    MENU,
    SUB_MENU,
    MENU_ITEM,
    SEARCH_FILTER,
    SEARCH_FILTER_SORT,
    REGISTRATION_FORM,
    MODAL
  ],
  color: 'blue',
  group: 'ui'
};
