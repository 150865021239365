import React from 'react';
import { RiContactsBookLine } from 'react-icons/ri';

const YachtIcon = () => (
  <span>
    <RiContactsBookLine style={{ color: '#63779C', width: '24px', height: '24px' }} />
  </span>
);

export default YachtIcon;
