import { colorOptions } from '../constants';

export const contactForm = {
  type: 'contact-form',
  name: 'Contact Form',
  icon: 'fas fa-envelope',
  settings: {},
  formSchema: {
    properties: {
      name: {
        type: 'string',
        showInForm: true,
        formFieldType: 'text'
      },
      color: {
        type: 'string',
        showInForm: true,
        formFieldType: 'select',
        options: colorOptions,
        selected: colorOptions[0][0]
      }
    },
    required: ['color']
  },
  color: 'blue',
  group: 'ui'
};
