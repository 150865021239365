const sizeOptions = [
  ['', 'Default'],
  ['card', 'Card'],
  ['contact-and-request-call', 'Message, phone, request call back (desktop)'],
  ['contact-and-request-call-mobile', 'Message, phone, request call back (mobile)'],
  ['sidebar', 'Sidebar (legacy)'],
  ['sidebar-new', 'Sidebar (new)'],
  ['sidebar-new-charter', 'Sidebar charter'],
  ['small', 'Small'],
  ['specs-and-deckplans', 'Specs, deckplans, brochure']
];

export const ecommerceButtons = {
  type: 'ecommerce-buttons',
  name: 'Ecommerce: Buttons',
  icon: 'fas fa-phone-volume',
  settings: {},
  formSchema: {
    properties: {
      name: {
        type: 'string',
        showInForm: true,
        formFieldType: 'text'
      },
      size: {
        type: 'string',
        showInForm: true,
        formFieldType: 'select',
        options: sizeOptions,
        selected: sizeOptions[0][0]
      }
    },
    required: ['size']
  },
  color: 'deep-purple',
  group: 'data'
};
