import { commonProperties } from '../constants';

export const boatProFooter = {
  type: 'boat-pro-footer',
  name: 'BOAT Pro Footer',
  icon: 'fas fa-bars',
  settings: {},
  formSchema: {
    properties: {
      ...commonProperties,
      backgroundImage: {
        type: 'string',
        showInForm: true,
        formFieldType: 'text'
      }
    },
    required: ['backgroundImage']
  },
  allowChildren: false,
  color: 'blue',
  group: 'ui'
};
