export const cardsBlock = {
  type: 'cards-block',
  name: 'Cards Block',
  icon: 'fas fa-th',
  settings: {},
  formSchema: {
    properties: {
      name: {
        type: 'string',
        showInForm: true,
        formFieldType: 'text'
      },
      type: {
        label: 'Type',
        type: 'string',
        showInForm: true,
        formFieldType: 'select',
        options: [
          ['article-hero', 'Article (hero)'],
          ['article-two-cols-portrait', 'Article (two cols)'],
          ['article-two-cols-landscape', 'Article (two cols, landscape)'],
          ['article-three-cols', 'Article (three cols)'],
          ['article-four-cols', 'Article (four cols)'],
          ['article-sidebar', 'Article (in sidebar)'],
          ['article-hierarchy', 'Article (hierarchy: 1 card + 2 cards + 4 cards)'],
          ['article-hierarchy-short', 'Article (short hierarchy: 1 card + 2 cards)'],
          ['article-landscape-with-ad', 'Article (landscape + banner)'],
          ['boat-presents', 'BOAT Presents'],
          ['carousel', 'Slider'],
          ['sponsored-listing', 'Sponsored listings'],
          ['yacht-sidebar-portrait', 'Yacht (in sidebar, large)'],
          ['yacht-sidebar-landscape', 'Yacht (in sidebar)'],
          ['yacht-search-result', 'Yacht (search result)'],
          ['yacht', 'Yacht (three cols)'],
          ['yacht-search-link', 'Yacht search link']
        ]
      },
      channel: {
        label: 'Channel',
        type: 'string',
        showInForm: true,
        formFieldType: 'select',
        options: [
          ['', 'Inherit'],
          ['business', 'BOAT Business'],
          ['boat-life', 'BOAT Life'],
          ['boat-pro', 'BOAT Pro'],
          ['destinations', 'Destinations'],
          ['events', 'Events'],
          ['market-intel', 'Market Intelligence'],
          ['syd', 'SYD'],
          ['vbs', 'Virtual Boat Show'],
          ['yachts', 'Yachts'],
          ['yfc', 'Yachts for Charter'],
          ['yfs', 'Yachts for Sale']
        ]
      },
      cardsSeparator: {
        label: 'Use separators between cards',
        type: 'string',
        showInForm: true,
        formFieldType: 'select',
        options: [['false', 'False'], ['true', 'True']]
      },
      combined: {
        label: 'Do you want this block to stick to the following one?',
        type: 'string',
        showInForm: true,
        formFieldType: 'select',
        options: [['false', 'False'], ['true', 'True']]
      }
    },
    required: ['name', 'type']
  },
  allowChildren: true,
  color: 'green',
  group: 'layout'
};
