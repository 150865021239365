import monitor from '@blaze-cms/plugin-monitor';
import serverMonitors from '@blaze-cms/plugin-monitor/src/server-monitors';
import sentry from '@blaze-cms/plugin-sentry/src/utils/browser';
import abacusAuth from '@blaze-cms/plugin-auth-abacus-ui';
import shorthand from '@blaze-cms/plugin-shorthand-ui';
import coreAdminPlugin from '@blaze-cms/plugin-admin-core-ui';
import example from './src/blaze-plugins/example';
import local from './src/blaze-plugins/boat-local';
import YachtIcon from './src/icons/YachtIcon';
import BrokerIcon from './src/icons/BrokerIcon';

export default function buildConfig(config) {
  serverMonitors('admin', [sentry]);

  config.plugins.set(coreAdminPlugin, {
    menuConfig: {
      main: {
        items: {
          yachts: {
            order: 12,
            label: 'Yachts',
            icon: YachtIcon,
            items: {}
          },
          broker: {
            order: 13,
            label: 'Broker',
            icon: BrokerIcon,
            items: {}
          }
        }
      }
    }
  });

  config.plugins.set(monitor, {});
  config.plugins.set(example, {});
  config.plugins.set(local, {});
  config.plugins.set(abacusAuth, {});
  config.plugins.set(shorthand, {});
  return config;
}
