import { boatProFooter } from './boat-pro-footer';
import { cardsBlock } from './cards-block';
import { charterRateSummary } from './charter-rate-summary';
import { contactButtons } from './contact-buttons';
import { contactForm } from './contact-form';
import { ecommerceButtons } from './ecommerce-buttons';
import { ecommerceForm } from './ecommerce-form';
import { ecommerceImpression } from './ecommerce-impression';
import { ecommerceProductView } from './ecommerce-product-view';
import { header } from './header';
import { newsletterSignup } from './newsletter-signup';
import { onwardJourney } from './onward-journey';
import { registrationForm } from './registration-form';
import { restrictedUserUpdateForm } from './restricted-user-update-form';
import { salesforceLead } from './salesforce-lead';
import { stickyRow } from './sticky-row';
import { visibilityTracking } from './visibility-tracking';
import { yachtDescription } from './yacht-description';
import { yachtSearchLink } from './yacht-search-link';
import { userAccessRefresh } from './user-access-refresh';

const components = [
  boatProFooter,
  cardsBlock,
  charterRateSummary,
  contactButtons,
  contactForm,
  ecommerceButtons,
  ecommerceForm,
  ecommerceImpression,
  ecommerceProductView,
  header,
  newsletterSignup,
  onwardJourney,
  registrationForm,
  restrictedUserUpdateForm,
  salesforceLead,
  stickyRow,
  visibilityTracking,
  yachtDescription,
  yachtSearchLink,
  userAccessRefresh
];

// if (process.env.BOAT_ENABLE_TEST_COMPONENTS === 'true') {
//   components.push(someDraftComponent);
// }

export default components;
