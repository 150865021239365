import { colorOptions } from '../constants';

const sizeOptions = [['', 'Default'], ['card', 'Card'], ['sidebar', 'Sidebar'], ['small', 'Small']];

export const contactButtons = {
  type: 'contact-buttons',
  name: 'Contact Buttons',
  icon: 'fas fa-phone-volume',
  settings: {},
  formSchema: {
    properties: {
      name: {
        type: 'string',
        showInForm: true,
        formFieldType: 'text'
      },
      color: {
        type: 'string',
        showInForm: true,
        formFieldType: 'select',
        options: colorOptions,
        selected: colorOptions[0][0]
      },
      size: {
        type: 'string',
        showInForm: true,
        formFieldType: 'select',
        options: sizeOptions,
        selected: sizeOptions[0][0]
      }
    },
    required: ['color', 'size']
  },
  color: 'blue',
  group: 'ui'
};
