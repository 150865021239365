import { promotionTypeOptions } from '../constants';

export const ecommerceImpression = {
  type: 'ecommerce-impression',
  name: 'Ecommerce: Impression',
  icon: 'fas fa-shopping-cart',
  settings: {},
  formSchema: {
    properties: {
      name: {
        type: 'string',
        showInForm: true,
        formFieldType: 'text'
      },
      listName: {
        type: 'string',
        showInForm: true,
        formFieldType: 'text'
      },
      promotionType: {
        type: 'string',
        showInForm: true,
        formFieldType: 'select',
        options: promotionTypeOptions,
        selected: promotionTypeOptions[0][0]
      }
    },
    required: ['listName', 'promotionType']
  },
  allowChildren: false,
  color: 'deep-purple',
  group: 'data'
};
