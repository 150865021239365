export const stickyRow = {
  type: 'sticky-row',
  name: 'Sticky Row',
  icon: 'fas fa-bars',
  settings: {},
  formSchema: {
    properties: {
      name: {
        type: 'string',
        showInForm: true,
        formFieldType: 'text'
      },
      context: {
        label: 'Context',
        type: 'string',
        showInForm: true,
        formFieldType: 'select',
        options: [['article', 'Article'], ['hub', 'Hub Page']],
        selected: 'article'
      }
    },
    required: ['name', 'context']
  },
  allowChildren: true,
  color: 'green',
  group: 'layout'
};
